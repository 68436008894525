import { Component, OnInit, Input, Directive, AfterViewInit, ElementRef,ViewChild } from '@angular/core';
import { BackendService } from "src/app/services/backend.service";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { PageScrollDirective } from 'src/app/page-scroll.directive';
import {UserLoginComponent} from 'src/app/user-login/user-login.component'
import { User } from '../models/userInfo.model';
import { OwlOptions } from 'ngx-owl-carousel-o';


 
@Component({
  selector: 'app-customer-portal',
  templateUrl: './customer-portal.component.html',
  styleUrls: ['./customer-portal.component.scss']
})

export class CustomerPortalComponent implements OnInit {
 
  public reachedTop = 0;
  href: string;
  public gmapUrl = "" ;
  public notApllicable="NA"
  public user:User;
  customOptions: OwlOptions = {
    loop: true,
    autoplay:true,
    center:true,
    autoHeight:true,
    autoWidth:true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
     
    },
    nav: true
  }

 @ViewChild('hero') homeElement : ElementRef
 @ViewChild('about') aboutElement: ElementRef
 @ViewChild('menu') menuElement : ElementRef
 @ViewChild('specials') specialsElement : ElementRef
 @ViewChild('testimonials') offersElement : ElementRef
 @ViewChild('gallery') galleryElement : ElementRef
 @ViewChild('contact') contactElemnt : ElementRef

 public currentActive=1;
 public homeOffset : Number =null;
 public aboutOffset : Number = null;
 public menuOffset : Number = null;
 public specialsOffset : Number = null;
 public offersOffset : Number = null;
 public galleryOffset : Number = null;
 public contactOffset : Number = null;
 
  @HostListener('window:scroll', ['$event']) 
  
  checkOffsetTop() { 
    if (window.pageYOffset >= this.homeOffset && window.pageYOffset < this.aboutOffset) {
      this.currentActive = 1;
    } else if (window.pageYOffset >= this.aboutOffset && window.pageYOffset < this.menuOffset) {
      this.currentActive = 2;
    } else if (window.pageYOffset >= this.menuOffset && window.pageYOffset < this.specialsOffset) {
      this.currentActive = 3;
    }  else if (window.pageYOffset >= this.specialsOffset && window.pageYOffset < this.offersOffset) {
      this.currentActive = 4;
    } else if (window.pageYOffset >= this.offersOffset && window.pageYOffset < this.galleryOffset) {
      this.currentActive = 5;
    } else if (window.pageYOffset >= this.galleryOffset && window.pageYOffset < this.contactOffset) {
      this.currentActive = 6;
    } else if (window.pageYOffset >= this.contactOffset) {
      this.currentActive = 7;
    } else {
      this.currentActive = 0;
    }
  }
  
  
  
  onScrollEvent($event){
    this.reachedTop = window.pageYOffset 
    || document.documentElement.scrollTop 
    || document.body.scrollTop || 0 ;
   
  } 

  @Input() restarantId: number;
  constructor(private backendServicce: BackendService, 
    private route: ActivatedRoute,private router:Router) {
      this.user=new User();
     }

  state = {
    restarantDetails: null, 
    rTimings: {},
    restarantImagePath: "",
    baseUrl:"",
    locationsFromGoogleApi: [],
    rLocationErr: "",
    rLocation: [],
    menuItems: [],
    filterMenu: [],
    categories: [],
    images: [],
    specialItemDesc:"",
    specialItemImage:"",
    specialItemTitle:"",
    template:"",
    userName:"",
    offers:[],
    adminDetails:[],
    features:[],
    deliveryTime:[],
    pickupTime:[]
    };
  activeTab="";
  timings = [];
  specialItems=[];
  activeCat="";
  loginFlag=false;
  isUserLoggedIn=false;

//imageUrl = "http://3.233.143.64";
imageUrl="https://buvvasmini.com"
// imageUrl="http://localhost:3001"
  
  ngOnInit(): void {
    this.state.baseUrl = this.backendServicce.baseUrl;
    this.route.params.subscribe((params: Params)=> {
      if(params.id !== undefined) {
        this.restarantId = params.id;
        this.fetchRestaurentDetails();
      }
    });
    if(sessionStorage.getItem('userDetails')!=null){
      this.state.userName=String(sessionStorage.getItem('userDetails'));
      this.isUserLoggedIn=true;
    }
    else
    {
      this.isUserLoggedIn=false;
    }
   // this.fetchRestaurentDetails();
  }
  ngAfterViewInit(): void{
    this.homeOffset=this.homeElement.nativeElement.offsetTop;
    this.aboutOffset=this.aboutElement.nativeElement.offsetTop;
    this.menuOffset=this.menuElement.nativeElement.offsetTop;
    this.specialsOffset=this.specialsElement.nativeElement.offsetTop;
    this.offersOffset=this.offersElement.nativeElement.offsetTop;
    this.galleryOffset=this.galleryElement.nativeElement.offsetTop;
    this.contactOffset=this.contactElemnt.nativeElement.offsetTop;
 }


  fetchRestaurentDetails() {
    this.backendServicce.getRestBranch(this.restarantId).subscribe((restarantData: any)=>{
      this.state.restarantDetails = restarantData.data.restarant;
      this.state.template=this.state.restarantDetails.rTemplate
      this.state.adminDetails=restarantData.data.adminDetails ? restarantData.data.adminDetails : ""
      this.state.restarantImagePath = restarantData.data.restarant.images[0];
      this.state.menuItems = restarantData.data.menu;
      this.state.filterMenu = restarantData.data.menu;
      this.state.categories = [...new Set(this.state.menuItems.map(items => items.categoryTitle))];
      this.filterMenuItems(this.state.categories[0])
      this.specialItems=this.state.menuItems.filter(item=> item.itemRecommended === 1)
      let parsedTimings = JSON.parse(this.state.restarantDetails.timings);
      let parsedOffers= this.state.restarantDetails.offers ? JSON.parse(this.state.restarantDetails.offers) : []
      let parsedfeatures=this.state.restarantDetails.features ? JSON.parse(this.state.restarantDetails.features) : []
      this.state.features=parsedfeatures.restaurant_features ? parsedfeatures.restaurant_features : [];
      this.state.offers=parsedOffers.restaurant_offers ? parsedOffers.restaurant_offers:[];
      this.state.images=this.state.restarantDetails.subImages? JSON.parse(this.state.restarantDetails.subImages) : []
      //this.gmapUrl = "https://www.google.com/maps/embed?center=" + this.state.restarantDetails.name + "," + this.state.restarantDetails.address ;
       this.gmapUrl ="https://www.google.com/maps/embed/v1/place?key=AIzaSyAzHbtqyANYzjgr_4eGXiWMBOPsWV1b6hM&q="   +this.state.restarantDetails.location;
       this.setSpecialItemData(this.specialItems[0]);
       this.sortWeekDays(parsedTimings)
      /*  this.state.deliveryTime=this.state.restarantDetails.deliveryTime? JSON.parse(this.state.restarantDetails.deliveryTime) : []
       this.state.pickupTime= this.state.restarantDetails.pickupTime? JSON.parse(this.state.restarantDetails.pickupTime) : [] */
    }
    );
  }

  scroll(el: HTMLElement,str:string,activeNum:number){
    el.scrollIntoView({behavior:"smooth"})
    this.activeTab=str;
    this.currentActive=activeNum
  }

  filterMenuItems(filterCat) {
    this.state.filterMenu = (this.state.menuItems && filterCat !== 'All' ? this.state.menuItems.filter(cat=>cat.categoryTitle === filterCat) : this.state.menuItems);
    this.activeCat=filterCat;
  }

  setSpecialItemData(item){
     this.state.specialItemDesc=item.itemDescription,
     this.state.specialItemImage=item.itemImage,
     this.state.specialItemTitle=item.itemTitle
  }

  sortWeekDays(timingsData){
    let data=timingsData.restaurant_timings
    const sorter = {
      // "sunday": 0, // << if sunday is first day of week
      "monday": 1,
      "tuesday": 2,
      "wednesday": 3,
      "thursday": 4,
      "friday": 5,
      "saturday": 6,
      "sunday": 7
    }
    this.timings=data.sort(function sortByDay(a, b) {
      let day1 = a.day.toLowerCase();
      let day2 = b.day.toLowerCase();
      return sorter[day1] - sorter[day2];
    });
  }
   
  setLoginFlag(){
    this.loginFlag=true;
  }
  
  getUserDetails(user:any){
    if(user)
    {
      // this.state.userName=(JSON.parse(user)).guest_name
      this.state.userName = String(sessionStorage.getItem('userDetails'));
       this.isUserLoggedIn=true;
    }
    else{
      alert("Login Failed.Please try again later!!")
    }

  }

  logout(){
    sessionStorage.clear();
    this.isUserLoggedIn=false;
  }
 
}
