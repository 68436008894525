import { Directive } from '@angular/core';

@Directive({
  selector: 'appPageScroll',
  inputs: ['href'],
  host: {
    "(click)": "linkClicked($event)"
  }
})

export class PageScrollDirective {

  href: string;

  linkClicked(e){ // handles click event, only prevents the links with a # in the beginning
    alert('clicked');
    e.preventDefault();
    this.scrollToID(this.href);
  }

  scrollToID(id){ // scrolls to the given id
    console.log(id)
    let target = document.querySelector(id);
    console.log(target)
    document.body.scrollTop = target.offsetTop;  
    // current scroll-target is body, you can take another input if you want
  }

}
