import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { ActivatedRoute, Params} from '@angular/router';
import { User,OtpVerification,PasswordLogin } from '../models/userInfo.model';
import { BackendService } from '../services/backend.service';



@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {
  isLogin = true;
  isSignUp = false;
  isPhoneExists=false;
  isOtpVerified=false;
  registerMsg=false;
  restaurantId:number;
  public user:User;
  public objOtpVerify:OtpVerification;
  public objPwdLogin:PasswordLogin;
  userLoginData={
    phone:""
  }
  savedData;
  otpId;

  //for sending data to another component
  @Output() public userObj=new EventEmitter();
  
 
  constructor(private backendService: BackendService,private route:ActivatedRoute) {
    this.user=new User();
    this.objOtpVerify=new OtpVerification();
    this.objPwdLogin=new PasswordLogin();
   }
  

  
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (params.id !== undefined) {
        this.restaurantId = params.id;
      }
    });
    this.isLogin = true; 
    this.isSignUp = false;
  }
  btnBackClick(modalType){
    if(modalType == "Login"){
      this.isLogin = true;
      this.isSignUp = false;
     
    }
   else if(modalType == "SignUp"){
     this.isLogin = false;
      this.isSignUp = true;
      this.registerMsg=false;
    }
  }

  onClickSignUp(){
    this.registerMsg=false;
    this.user.guest_status=1;
    this.user.restaurant_id=this.restaurantId;
    this.user.numSeats=0;
    this.savedData= this.backendService.saveNewUserData(this.user)
    console.log(this.savedData)
  }

  checkUserRegistered(){
   //alert(this.user.guest_phone)
    //console.log(JSON.stringify(this.userLoginData))
   this.backendService.checkUserExists(this.userLoginData).subscribe((resData:any)=>
    {
     if(resData.data){
      this.otpId=resData.data;
      this.isPhoneExists=true;
     }
   
    },err=>
    {
      console.log("error")
      this.registerMsg=true;
      this.isLogin=false;
      this.isSignUp=true;
    })
  
  }

  onClick_verifyOtp(){
     this.objOtpVerify.guest_phone=this.userLoginData.phone
     this.objOtpVerify.otp_id=this.otpId;
     this.backendService.verifyOTP(this.objOtpVerify).subscribe((data:any)=>{
       console.log(data);
       this.user.guest_name=data.jwtToken.user.userName;
       this.user.guest_email=data.jwtToken.user.userEmail;
       this.user.guest_phone=data.jwtToken.user.userPhone;
       this.user.guest_address=data.jwtToken.user.userAddress;
       this.isOtpVerified=true;
       //console.log("in user login"+this.user)
       this.userObj.emit(JSON.stringify(this.user))
     },err=>console.log(err.status))
    
  }

  onclick_Login(){
    this.objPwdLogin.phone=this.userLoginData.phone
    this.backendService.loginThroughPassword(this.objPwdLogin).subscribe((data:any)=>{
      console.log(data);
      this.user.guest_name=data.user.userName;
      this.user.guest_email=data.user.userEmail;
      this.user.guest_phone=data.user.userPhone;
      this.user.guest_address=data.user.userAddress;
      sessionStorage.setItem('userDetails',this.user.guest_name)
      this.userObj.emit(JSON.stringify(this.user))
    })
    
  }
}
