  <div class="modal fade"  id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    
    <div class="modal-content" *ngIf="this.isLogin">
      <div class="modal-header" style="background-color:  #cda45e;">
        <h5 class="modal-title" id="exampleModalLabel">Login</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label style="color: #37332a;"><b>Enter Mobile number</b></label>
         <input type="text" [(ngModel)]="this.userLoginData.phone" style="border: 1px solid  #37332a;" class="form-control" >
         <div  *ngIf="this.isPhoneExists">
          <label style="color: #37332a;"><b>Password</b></label>
          <input type="password"  [(ngModel)]="this.objPwdLogin.password"  style="border: 1px solid  #37332a;" class="form-control" >
        </div>
       <!--   <label style="color: #37332a;"><b>Verify OTP</b></label>
         <input type="text" placeholder="Enter OTP recieved on your mobile"  [(ngModel)]="this.objOtpVerify.otp"  style="border: 1px solid  #37332a;" class="form-control" > -->
       
       <!--   <label style="color: darkgreen;" *ngIf="this.isOtpVerified"><i>Verified Successfully</i></label> -->
        
         <div style="display: flex;"><label style="color: #37332a;">Don't have an Account?</label><b>

           <a  style="margin-left: 10px; font-size : 15px;"  (click)="btnBackClick('SignUp')">Sign Up</a></b></div>
      </div>
      <div class="modal-footer">
        <!-- <button *ngIf="!this.isPhoneExists" (click)="onClick_btnLogin()" class="btn-menu">Login</button>
        <button *ngIf="this.isPhoneExists" class="btn-menu" data-dismiss="modal" (click)="onClick_verifyOtp()">Verify OTP</button> -->
        <button *ngIf="!this.isPhoneExists" (click)="checkUserRegistered()" class="btn-menu">Verify Mobile Number</button>
        <button *ngIf="this.isPhoneExists" class="btn-menu" data-dismiss="modal" (click)="onclick_Login()">Login</button>
      </div>
    </div>
    </div>

    <!--Signup up modal-->
    <div class="modal-dialog" *ngIf="this.isSignUp" role="document">
    <div class="modal-content" >
      <div class="modal-header" style="background-color:  #cda45e;">
        <h5 class="modal-title" id="exampleModalLabel">Sign Up</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label *ngIf="this.registerMsg" style="color: firebrick;">Mobile number does not exist.Please register</label>
          <div class="form-group">
            <label style="color: #37332a;"><b>Enter Mobile number</b></label>
            <input type="text" [(ngModel)]="this.user.guest_phone" style="border: 1px solid  #37332a;" class="form-control" >
          </div>
          <div class="form-group">
            <label style="color: #37332a;"><b>Name</b></label>
            <input type="text" [(ngModel)]="this.user.guest_name" style="border: 1px solid  #37332a;" placeholder="Enter Name" class="form-control" required>
          </div>
          <div class="form-group">
            <label style="color: #37332a;"><b>Email</b></label>
            <input type="email"  [(ngModel)]="this.user.guest_email" style="border: 1px solid  #37332a;" placeholder="Enter Email" class="form-control" required>
          </div>
          <div class="form-group">
            <label style="color: #37332a;"><b>Address</b></label>
            <input type="email"  [(ngModel)]="this.user.guest_address" style="border: 1px solid  #37332a;" placeholder="Enter address" class="form-control" required>
          </div>
        <div class="form-group">
          <label style="color: #37332a;"><b>Password</b></label>
          <input type="password" [(ngModel)]="this.user.guest_pwd" style="border: 1px solid  #37332a;" placeholder="Enter Password" class="form-control" required>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
        <button type="button" class="btn-menu" (click)="btnBackClick('Login')">Back</button>
        <button type="button" class="btn-menu" (click)="onClickSignUp()">Sign Up</button>
      </div>
    </div>
    </div>
  </div> 

