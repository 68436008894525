import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { User,OtpVerification,PasswordLogin} from '../models/userInfo.model';






@Injectable({
  providedIn: 'root'
})
export class BackendService {

 public baseUrl = "https://buvvasmini.com/api";
//public baseUrl = "http://3.233.143.64/api";
 //public baseUrl="http://localhost:3001/api"
 public userData:any;
 
  constructor(private http: HttpClient) { }

  public getRestBranch(id: number){
  return this.http.get( this.baseUrl + "/get-restbranch-details?user_id=181&restarant_id=" + id);
  //return this.http.get( this.baseUrl + "/get-restarant-details?restarant_id=" + id);
  }

  public saveNewUserData(user:User){
      this.http.post(this.baseUrl+"/add-guest",user,{
        headers:new HttpHeaders({
          'Content-Type':'application/json'
        })
      }).toPromise().then(data=>{
         this.userData=data
      })
      return this.userData;
  }
  public checkUserExists(phone:any){
    return this.http.post(this.baseUrl+"/check-guest-exists",phone,{
      headers:new HttpHeaders({
        'Content-Type':'application/json'
      })
    })
  }

  public loginThroughOtp(phone:any){
  return this.http.post(this.baseUrl + "/guest-login",phone,{
      headers:new HttpHeaders({
        'Content-Type':'application/json'
      })
    })
  
  } 

  public verifyOTP(objVerifyOTP:OtpVerification){
   return this.http.post(this.baseUrl + "/guest-verify-otp",objVerifyOTP,{
    headers:new HttpHeaders({
      'Content-Type':'application/json'
      })
    })
  }
 
  public loginThroughPassword(objPwdLogin:PasswordLogin){
    return this.http.post(this.baseUrl + "/guest-login-through-password",objPwdLogin,{
      headers:new HttpHeaders({
        'Content-Type':'application/json'
        })
    })
  }

}
