import { Component } from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import * as AOS from "aos"


declare var $:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  title = 'buvvasCustomerPortal';
  restarantId = null;
  constructor(private route: ActivatedRoute, swUpdate: SwUpdate) {
    //swUpdate.activateUpdate().then(() => document.location.reload());
  }
  
  ngOnInit() {
    AOS.init();
  }
}
