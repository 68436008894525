import { Component, OnInit ,Input} from '@angular/core';
import { ComponentFactoryResolver } from "@angular/core";
import { ViewContainerRef } from "@angular/core";

import { CustomerPortalComponent } from '../customer-portal/customer-portal.component';
import {CustomerPortalNewtemplateComponent} from '../customer-portal-newtemplate/customer-portal-newtemplate.component'
import{BackendService} from '../services/backend.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-template-switch',
  templateUrl: './template-switch.component.html',
  styleUrls: ['./template-switch.component.scss']
})
export class TemplateSwitchComponent implements OnInit {
  private componentFactoryResolver: ComponentFactoryResolver;
	private backendService: BackendService;
	private viewContainerRef: ViewContainerRef;
  constructor(componentFactoryResolver: ComponentFactoryResolver,
		backendService: BackendService,
		viewContainerRef: ViewContainerRef,private route: ActivatedRoute) { 
      this.componentFactoryResolver = componentFactoryResolver;
      this.backendService = backendService;
      this.viewContainerRef = viewContainerRef;
    }
    @Input() restarantId: number;
    state = {
      restarantDetails: null, 
      baseUrl:"",
      templateOne:false,
      templateTwo:false
    };
  ngOnInit(): void {
    this.state.baseUrl = this.backendService.baseUrl;
    this.route.params.subscribe((params: Params)=> {
      if(params.id !== undefined) {
        this.restarantId = params.id;
        this.fetchRestaurentDetails();
      }
    });
  
  }

  fetchRestaurentDetails() {
    this.backendService.getRestBranch(this.restarantId).subscribe((restarantData: any)=>{
      this.state.restarantDetails = restarantData.data.restarant;
      if(this.state.restarantDetails.rTemplate=='Template1')
      {
        this.state.templateOne=true
      }
      else if(this.state.restarantDetails.rTemplate=='Template2')
      {
        this.state.templateTwo=true
      }
      else if(this.state.restarantDetails.rTemplate==null)
      {
        this.state.templateOne=true
      } 

    }
    );
  }

}
