
<title>{{this.state.restarantDetails.name}}</title>
<body>

    <!-- ======= Top Bar ======= -->
    <section id="topbar" class="d-none d-lg-flex align-items-center fixed-top topbar-transparent">
        <div class="container text-right">
            <a href="tel:{{this.state.restarantDetails.phone}}">   <i class="icofont-phone"></i></a> Contact:    <a href="tel:{{this.state.restarantDetails.phone}}">{{this.state.restarantDetails.phone}} </a> 
            <!-- <i class="icofont-clock-time icofont-rotate-180"></i> Mon-Sat: 11:00 AM - 23:00 PM -->
        </div>
    </section>

    <!-- ======= Header ======= -->
    <header id="header" class="fixed-top d-flex align-items-center header-transparent">
        <div class="container d-flex align-items-center">

            <div class="logo mr-auto">
                <h1 class="text-light"><a
                    (click)="scroll(about,'about')"><span>{{this.state.restarantDetails.name}}</span></a></h1>
                <!-- Uncomment below if you prefer to use an image logo -->
                <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
            </div>

            <nav class="nav-menu d-none d-lg-block">
                <ul>
                    <li [ngClass]="{'active': this.currentActive==1}"><a (click)="scroll(hero,'hero')">Home</a></li>
                    <li *ngIf="this.state.restarantDetails.about" [ngClass]="{'active': this.currentActive==2}"><a
                            (click)="scroll(about,'about')">About</a></li>
                    <li *ngIf="this.state.menuItems.length>0" [ngClass]="{'active': this.currentActive==3}"><a
                            (click)="scroll(menu,'menu')">Menu</a></li>
                    <li *ngIf="this.specialItems.length>0" [ngClass]="{'active': this.currentActive==4}"><a
                            (click)="scroll(specials,'specials')">Specials</a></li>
                    <li *ngIf="this.state.offers.length>0" [ngClass]="{'active': this.currentActive==5}"><a
                            (click)="scroll(chefs,'chefs')">Offers</a></li>
                    <li *ngIf="this.state.images.length>0" [ngClass]="{'active': this.currentActive==6}"><a
                            (click)="scroll(gallery,'gallery')">Gallery</a></li>
                    <li [ngClass]="{'active': this.currentActive==7}"><a
                            (click)="scroll(contact,'contact')">Contact</a></li>
                  
                </ul>
            </nav>
            <!-- .nav-menu -->
        </div>
    </header><!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <section id="hero"  #hero>
        <div class="hero-container">
            <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">

                <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

                <div class="carousel-inner" role="listbox">

                    <!-- Slide 1 -->
                    <div class="carousel-item active"
                        [style]="'background:url(' + this.imageUrl + this.state.restarantImagePath + ');background-repeat:no-repeat;background-size:cover'">
                        <div class="carousel-container">
                            <div class="carousel-content">
                                <h2 class="animate__animated animate__fadeInDown">
                                    <span>{{this.state.restarantDetails.tagline}}</span></h2>
                                    <h3> <span *ngIf="this.state.restarantDetails.pureVeg!=0 && this.state.restarantDetails.pureVeg!=null" style="margin-right: 4px;" class="badge badge-success badge-pill">Pure Veg</span> <span *ngIf="this.state.restarantDetails.speciality!=null" class="badge badge-warning badge-pill">{{this.state.restarantDetails.speciality}}</span></h3>
                                <div>
                                    <a (click)="scroll(menu,'menu')"
                                        class="btn-menu animate__animated animate__fadeInUp scrollto">Our
                                        Menu</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <!-- Slide 3 -->
                  
                  <!--   <div class="carousel-item" *ngFor="let o of this.state.offers;let i=index"  [style]="'background:url(' + this.imageUrl + o.offerImg + ');background-repeat:no-repeat;background-size:cover'" >
                      
                        <div class="carousel-container">
                            <div class="carousel-content">
                                <h2 class="animate__animated animate__fadeInDown">
                                    {{o.offers}}</h2>
                             
                            </div>
                        </div>
                    </div> -->
               
                </div>

              <!--   <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon icofont-simple-left" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>

                <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                    <span class="carousel-control-next-icon icofont-simple-right" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
 -->
            </div>
        </div>
    </section><!-- End Hero -->

    <main id="main">

        <!-- ======= About Section ======= -->
        <section id="about" class="about" #about>
            <div class="container-fluid" *ngIf="this.state.restarantDetails.about">
               
                    <div class="section-title">
                        <h2><span>About</span> Us</h2>
                    </div>
               
                    <div class="d-flex flex-column justify-content-center align-items-stretch">

                        <div class="content">
                            <p class="font-italic" [innerHTML]="this.state.restarantDetails.about"></p>
                        </div>

                    </div>
               
            

            </div>
        </section><!-- End About Section -->
       <!--  <section id="why-us" class="why-us" *ngIf="this.state.offers.length>0">
            <div class="container">
      
              <div class="section-title">
                <h2>Restaurant <span>Offers</span></h2>
               
              </div>
      
              <div class="row">
      
                <div class="col-lg-4" *ngFor="let o of this.state.offers;let i=index">
                  <div class="box">
                    <h4>  {{o.offers}}</h4>
                  </div>
                </div>
      
              
              </div>
      
            </div>
          </section> --><!-- End Whu Us Section -->
        <!-- ======= Menu Section ======= --> 
        <section id="menu" class="menu" #menu>
            <div *ngIf="this.state.menuItems.length>0" class="container">

                <div class="section-title">
                    <h2>Check our tasty <span>Menu</span></h2>
                </div>

                <div class="row" data-aos="fade-up" data-aos-delay="100">
                    <div class="col-lg-12 d-flex justify-content-center">
                        <ul id="menu-flters">
                            <li *ngFor="let cat of this.state.categories" [ngClass]="{'filter-active':this.activeCat==cat}" (click)="this.filterMenuItems(cat)"> {{cat}}
                            </li>
                            <li (click)="this.filterMenuItems('All')"  [ngClass]="{'filter-active':this.activeCat=='All'}">Show All</li>
                        </ul>
                    </div>
                </div>

                <div class="row menu-container"  data-aos="fade-up" data-aos-delay="200" style="overflow-y: auto;height: 300px;">

                    <div *ngFor="let item of this.state.filterMenu" class="col-lg-6 menu-item ">
                        <ng-template [ngIf]="item.itemImage!=null" [ngIfElse]="elseBlock"> 
                        <a [href]="this.imageUrl + item.itemImage" [attr.data-lightbox]="item.itemImage" data-title={{item.itemTitle}}>
                            <img  [src]="this.imageUrl + item.itemImage" class="menu-img" alt="" />
                        </a>
                        </ng-template> 
                        <ng-template #elseBlock> 
                             <img  [src]="this.imageUrl + this.state.restarantImagePath" class="menu-img" alt="" />
                        </ng-template>
                        <div class="menu-content">
                            <a>{{item.itemTitle}}</a><span>{{item.itemPrice | currency:"&#8377;"}}/-</span>
                           
                        </div>
                        
                        
                        <div class="menu-ingredients">
                         <div class="row">
                          <div class="col-lg-9">    
                          {{item.itemDescription}}
                        </div>
                        <div *ngIf="item.itemMarkupPrice" class="col-lg-3">
                            <del style="padding-left: 10px;font-size: 16px;"><b> {{item.itemMarkupPrice | currency:"&#8377;"}}/-</b></del>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </section><!-- End Menu Section -->

        <!-- ======= Specials Section ======= -->
        <section id="specials" class="specials" #specials>
            <div *ngIf="this.specialItems.length>0" class="container">
                <div class="section-title">
                    <h2>Specials</h2>
                </div>

                <div class="row">
                    <div class="col-lg-3">
                        <ul class="nav nav-tabs flex-column">
                            <li *ngFor="let item of this.specialItems" class="nav-item">
                                <a class="nav-link  show"  data-toggle="tab"
                                    (click)="setSpecialItemData(item)">{{item.itemTitle}}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="col-lg-9 mt-4 mt-lg-0">
                        <div class="tab-content">
                            <div class="tab-pane active show" id="tab-1">
                                <div class="row">
                                    <div class="col-lg-8 details order-2 order-lg-1">
                                        <h3>{{this.state.specialItemTitle}}</h3>
                                        <p class="font-italic">{{this.state.specialItemDesc}}</p>
                                    </div>
                                    <div class="col-lg-4 text-center order-1 order-lg-2">
                                        <ng-template [ngIf]="this.state.specialItemImage!==''" [ngIfElse]="spelseBlock"><a [href]="this.imageUrl + this.state.specialItemImage" [attr.data-lightbox]="this.imageUrl + this.state.specialItemImage"> <img class="img-fluid" [src]="this.imageUrl + this.state.specialItemImage" style="border-radius: 50%;width: 225px;height: 225px;" alt="" /></a></ng-template> 
                                        <ng-template #spelseBlock>  <img class="img-fluid"  [src]="this.imageUrl + this.state.restarantImagePath" style="border-radius: 50%;width: 225px;height: 225px;" alt="" /></ng-template>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </section><!-- End Specials Section -->
          <!-- ======= Chefs Section ======= -->
    <section id="chefs"  class="chefs"  #chefs>
        <div class="container" *ngIf="this.state.offers.length>0">
  
          <div class="section-title">
            <h2>Restaurant <span>Offers</span></h2>
          
          </div>
  
          <div class="row">
  
            <div class="col-lg-4 col-md-6" *ngFor="let o of this.state.offers;let i=index" >
              <div class="member">
                <ng-template [ngIf]="o.offerImg!=''" [ngIfElse]="offerImgElseBlock">
                <div class="pic">
                    <a [href]="this.imageUrl + o.offerImg" [attr.data-lightbox]="o.offerImg">  <img [src]="this.imageUrl+o.offerImg" class="img-fluid"  style="width: 350px;height: 300px;"  alt=""> </a>
                </div>
            </ng-template>
             <ng-template #offerImgElseBlock>
                <div class="pic"><img [src]="this.imageUrl+ this.state.restarantImagePath" class="img-fluid"  style="width: 350px;height: 300px;"  alt=""></div>
             </ng-template>
                <div class="member-info" *ngIf="o.offers!=''">
                  <h4>{{o.offers}}</h4>
                </div>
              </div>
            </div>

          </div>
  
        </div>
      </section><!-- End Chefs Section -->


        <!-- ======= Gallery Section ======= -->
        <section   id="gallery" class="gallery" #gallery>
            <div  class="container" *ngIf="this.state.images.length>0">

                <div class="section-title">
                    <h2>Gallery</h2>
                </div>

                <div class="row no-gutters">
                    <div class="col-lg-3 col-md-4" *ngFor="let imgItem of this.state.images">
                        <div class="gallery-item">
                            <a [href]="this.imageUrl + imgItem" data-lightbox="imgItem">
                                <img [src]="this.imageUrl + imgItem" class="img-fluid" />
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <!-- End Gallery Section --> 
      
        <!-- ======= Contact Section ======= -->
        <section id="contact" class="contact" #contact>
            <div class="container">

                <div class="section-title">
                    <h2><span>Contact</span> Us</h2>
                </div>
           

            <div class="map">
                <iframe style="border:0; width: 100%; height: 350px;" [src]="this.gmapUrl | safe" frameborder="0"
                    allowfullscreen></iframe>
            </div>
        </div>

            <div class="container mt-5">
                <div class="info-wrap">
                    <div class="row">
                        <div *ngIf="this.state.restarantDetails.address" class="col-lg-3 col-md-6 info">
                            <i class="icofont-google-map"></i>
                            <h4>Location:</h4>
                            <p>{{this.state.restarantDetails.address}}</p>
                        </div>

                        <div *ngIf="this.timings.length>0" class="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                            <i class="icofont-clock-time icofont-rotate-90"></i>
                            <h4>Open Hours:</h4>
                            <p *ngFor="let timing of this.timings">
                                <b>{{timing.day | uppercase}}</b> : <br>
                                {{ timing.slots[0].start_time | convertFrom24To12Format }} To
                                {{timing.slots[0].end_time | convertFrom24To12Format }}
                        </div>

                        <div *ngIf="this.state.adminDetails.length"  class="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                            <a href="mailto:{{this.state.adminDetails[0].email}}" style="color: white;"> <i class="icofont-envelope"></i> </a>
                            <h4>Email:</h4>
                            <a href="mailto:{{this.state.adminDetails[0].email}}">   <p>{{this.state.adminDetails[0].email}}</p> </a>
                        </div>

                        <div *ngIf="this.state.restarantDetails.phone" class="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                            <a href="tel:{{this.state.restarantDetails.phone}}" style="color: white;">  <i class="icofont-phone"></i> </a>
                            <h4>Call:</h4>
                            <a href="tel:{{this.state.restarantDetails.phone}}">  <p>{{this.state.restarantDetails.phone}}</p> </a>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <!-- End Contact Section -->

    </main><!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer id="footer">
        <div class="container">
            <div *ngIf="this.state.features.length>0" class="d-flex justify-content-center mt-3">
                <label> Services </label>
            </div>
        <div class="row">
            
          <div class="col-lg-2 col-md-6 "></div>
          <div *ngIf="this.state.features.length>0" class="col-lg-8 col-md-6 d-flex justify-content-center">
           <h4> <span *ngFor="let f of this.state.features"  style="margin-right: 4px;" class="badge badge-warning badge-pill">{{f.feature}}</span></h4>
          </div>
          <div class="col-lg-2 col-md-6"></div>
          
            <!-- <div class="col-lg-4 col-md-6 footer-links" *ngIf="this.state.restarantDetails.pureVeg!=null || this.state.restarantDetails.speciality!=null || this.state.deliveryTime.length>0 || this.state.pickupTime.length>0">
              <label> Our Specialty </label>
                <ul>
                  <li *ngIf="this.state.restarantDetails.pureVeg!=null"><i class="bx bx-chevron-right" ></i> <a >Pure Veg</a></li>
                  <li *ngIf="this.state.restarantDetails.speciality!=null"><i class="bx bx-chevron-right" ></i> <a>{{this.state.restarantDetails.speciality}}</a></li>
                  <li *ngIf="this.state.deliveryTime.length>0"><i class="bx bx-chevron-right"  ></i> <a>Delivery Time : {{this.state.deliveryTime[0]}} to {{this.state.deliveryTime[1]}} mins</a></li>
                  <li *ngIf="this.state.pickupTime.length>0"><i class="bx bx-chevron-right" ></i> <a>Pickup Time : {{this.state.pickupTime[0]}} to {{this.state.pickupTime[1]}} mins</a></li>
                </ul>
              </div>
    
              <div *ngIf="this.state.features.length>0" class="col-lg-4 col-md-6 footer-links">
                <label> Our Features </label>
                <ul>
                  <li *ngFor="let f of this.state.features"><i class="bx bx-chevron-right"></i> <a>{{f.feature}}</a></li>
                 
                </ul>
              </div> -->
            </div>
            <div class="social-links mt-3 mb-3">
                <a *ngIf="this.state.restarantDetails.link" [href]="this.state.restarantDetails.link" target="_blank" class="youtube"><i
                    class="bx bxl-youtube"></i></a>
                <a *ngIf="this.state.restarantDetails.twiterlink" [href]="this.state.restarantDetails.twiterlink" target="_blank" class="twitter"><i
                        class="bx bxl-twitter"></i></a>
                <a *ngIf="this.state.restarantDetails.fblink" [href]="this.state.restarantDetails.fblink" target="_blank" class="facebook"><i
                        class="bx bxl-facebook"></i></a>
                <a *ngIf="this.state.restarantDetails.instalink" [href]="this.state.restarantDetails.instalink" target="_blank" class="instagram"><i
                        class="bx bxl-instagram"></i></a>
            </div>
            <div class="copyright">
                &copy; Copyright <strong><span>Buvvas</span></strong>. All Rights Reserved
            </div>
            <div class="credits">
                Designed by <a href="https://buvvas.com/">Buvvas</a>
            </div>
        </div>
    </footer><!-- End Footer -->

    <a (click)="scroll(hero,'hero')"  class="back-to-top"><i class="icofont-simple-up"></i></a>
    <app-user-login *ngIf="this.loginFlag"></app-user-login>
</body>
