export class User{
  guest_name:string;
  guest_phone:string;
  restaurant_id:number;
  guest_address:string;
  guest_pwd:string;
  guest_status:number;
  guest_email:string
  numSeats
}

export class OtpVerification{
  otp_id: string;
  otp:number;
  guest_phone:string;
}

export class PasswordLogin{
  phone:string;
  password:string;
}