import { NgModule, OnInit,Input } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import {TemplateSwitchComponent} from './template-switch/template-switch.component'

const routes: Routes = [
 {
   path:':id',
   component:TemplateSwitchComponent,
 }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})


export class AppRoutingModule implements OnInit{ 

  constructor() {}
  
    ngOnInit(): void { }

} 
