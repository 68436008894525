<title>{{this.state.restarantDetails.name}}</title>

<body>

<div (scroll) = "onScrollEvent($event)">

  <div id="topbar" [class]="'d-flex align-items-center fixed-top' + (this.reachedTop?' topbar-scrolled':'')">
    <div class="container d-flex">
      <div class="contact-info mr-auto">
        <a href="tel:{{this.state.restarantDetails.phone}}">   <i class="icofont-phone"></i> </a>  Contact: <a href="tel:{{this.state.restarantDetails.phone}}">{{this.state.restarantDetails.phone}} </a>
      </div>
     <!--  <div *ngIf="this.isUserLoggedIn">  
        <i class="icofont-user"></i> <b>Welcome:</b> {{this.state.userName}}
      </div> -->
    </div>
  </div>
  <header id="header" [class]="'fixed-top' + (this.reachedTop?' header-scrolled':'')" >
    <div class="container d-flex align-items-center">
    <h1 class="logo mr-auto"><a (click)="scroll(about,'about',2)">{{this.state.restarantDetails.name}}</a></h1>
      <!-- <a href="/CustomerPortal" class="logo mr-auto">
        <img src="assets/img/logo.png" alt="" class="img-fluid" />
      </a> -->
      <nav class="nav-menu d-none d-lg-block">
        <ul>
          <li [ngClass]="{'active': this.currentActive==1}"><a (click)="scroll(hero,'hero',1)">Home</a></li>
          <li *ngIf="this.state.restarantDetails.about" [ngClass]="{'active': this.currentActive==2}"><a (click)="scroll(about,'about',2)">About</a></li>
          <li *ngIf="this.state.menuItems.length>0" [ngClass]="{'active': this.currentActive==3}"><a (click)="scroll(menu,'menu',3)">Menu</a></li>
          <li *ngIf="this.specialItems.length>0" [ngClass]="{'active': this.currentActive==4}"><a (click)="scroll(specials,'specials',4)">Specials</a></li>
          <li *ngIf="this.state.offers.length>0" [ngClass]="{'active': this.currentActive==5}"><a (click)="scroll(testimonials,'testimonials',5)">Offers</a></li>
          <li *ngIf="this.state.images.length>0" [ngClass]="{'active': this.currentActive==6}"><a (click)="scroll(gallery,'gallery',6)">Gallery</a></li>
          <li [ngClass]="{'active': this.currentActive==7}"><a (click)="scroll(contact,'contact',7)">Contact</a></li>
        
            <ul>
           <!--    <li><a href="#">Profile</a></li> -->
           <!--    <li><a (click)="logout()">Logout</a></li> -->
            </ul>
      
        <!--   <li class="book-a-table text-center"><a (click)="setLoginFlag()"  data-toggle="modal" data-target="#loginModal">Order Online</a></li> -->
        </ul>
      </nav>
    </div>
  </header>
  <section id="hero" class="d-flex align-items-center" 
    [style] = "'backgroundImage:url(' + this.imageUrl + this.state.restarantImagePath + ')'" #hero>
    <div class="container position-relative text-center text-lg-left" data-aos="zoom-in" data-aos-delay="100">
      <div class="row"> 
        <div class="col-lg-12 align-items-center justify-content-center">
         <!--  <h1>Welcome to <span> {{this.state.restarantDetails.name}}</span></h1> -->
          <h1>{{this.state.restarantDetails.tagline}}</h1>
          <h4 > <span *ngIf="this.state.restarantDetails.pureVeg!=null && this.state.restarantDetails.pureVeg!=0" style="margin-right: 4px;" class="badge badge-success badge-pill">Pure Veg</span> <span *ngIf="this.state.restarantDetails.speciality!=null" class="badge badge-warning badge-pill">{{this.state.restarantDetails.speciality}}</span></h4>
       
          <div class="btns">
            <!-- <button (click)="scroll(menu)" class="btn-menu animated fadeInUp scrollto">Our Menu</button> -->
         <a (click)="scroll(menu,'menu',3)" class="btn-menu animated fadeInUp scrollto">Our Menu</a>
         <!-- <a (click)="loginNavigate()" class="btn-menu animated fadeInUp scrollto">Order Online</a> -->
           
          </div>
        </div>
      </div>
    </div>
  </section>
  <main id="main">
     
    <section class="about" id="about"
      [style] = "'backgroundImage:url(' + this.imageUrl + this.state.restarantImagePath + ')'" #about>
      <div class="container" *ngIf="this.state.restarantDetails.about" data-aos="fade-up">
        <div class="section-title">
          <h2>About us</h2>
        </div>

        <div class="row">
         <!--  <div class="col-lg-6 order-1 order-lg-2" data-aos="zoom-in" data-aos-delay="100">
            <div class="about-img">
              <img [src]= "this.imageUrl + this.state.restarantImagePath" alt="" />
            </div>
          </div> -->
          <ng-template [ngIf]="this.state.restarantDetails.link" [ngIfElse]="aboutElseBlock">
          <div class="col-lg-8 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <p style="color: white;" [innerHTML]="this.state.restarantDetails.about">
            </p>
          </div>
         
          <div  class="col-lg-4 d-flex order-1 order-lg-2" data-aos="zoom-in" data-aos-delay="200">
            <a [href]="this.state.restarantDetails.link" class="venobox play-btn" data-vbtype="video" target="_blank" data-autoplay="true"></a>
          </div>
     
          </ng-template>
        <ng-template #aboutElseBlock>
          <div class="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <p style="color: white;" [innerHTML]="this.state.restarantDetails.about">
            </p>
          </div>
        </ng-template>
        </div>

      </div>
    </section>
       <!-- ======= Restaurant Offers Section ======= -->
       <!-- <section id="testimonials" class="testimonials section-bg">
        <div class="container" data-aos="fade-up">
  
          <div class="section-title">
            <h2>Restaurant Offers</h2>
           
          </div>
  
          <div class="testimonials-carousel"  data-aos="zoom-in" data-aos-delay="100">
  
            <div class="testimonial-item" *ngFor="let o of this.state.offers">
              <p>
                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                 {{o.offers}}
                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
              <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
              <h3>Saul Goodman</h3>
              <h4>Ceo &amp; Founder</h4> 
            </div>
  
           
          </div>
  
        </div>
      </section> --><!-- End Testimonials Section -->

  <!-- ======= Why Us Section ======= -->
 <!--  <section id="why-us" class="why-us" *ngIf="this.state.offers.length>0">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Restaurant Offers- Corousel</h2>
       
      </div>

      <div class="row">

        <div> -->
          
           <!--  <div  *ngFor="let o of this.state.offers">  
           
            <img  [src]="this.imageUrl + o.offerImg"/>  
           
            </div>   -->
            <!-- <owl-carousel [options]="{items: 3, dots: false, navigation: false}" [items]="this.state.offers"   [carouselClasses]="['owl-theme', 'row', 'sliding']" > 
            <div data-aos="zoom-in" data-aos-delay="100">
            
              <div class="" *ngFor="let o of this.state.offers">
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                 {{o.offers}}
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img [src]="this.imageUrl + o.offerImg" class="testimonial-img" alt="">
               
              </div>
           
            </div>
          </owl-carousel> -->   
          <!-- <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let o of this.state.offers;let i=index">
              <ng-template carouselSlide [id]="i">
                <img [src]="this.imageUrl + o.offerImg">
              </ng-template>
            </ng-container>
          </owl-carousel-o> -->
         <!--  <div class="box" data-aos="zoom-in" data-aos-delay="100">
            <h4>  {{o.offers}}</h4>
          </div> -->
        <!-- </div>

       
      </div>

    </div>
  </section> --><!-- End Why Us Section -->
  
    <section class="menu section-bg" id="menu" #menu>
      <div *ngIf="this.state.menuItems.length>0" class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Menu</h2>
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="100" >
          <div class="col-lg-12 d-flex justify-content-center">
            <ul id="menu-flters">
              <li *ngFor="let cat of this.state.categories" [ngClass]="{'filter-active':this.activeCat==cat}" (click)="this.filterMenuItems(cat)"> {{cat}}</li>
              <li (click)="this.filterMenuItems('All')" [ngClass]="{'filter-active':this.activeCat=='All'}">All</li>
            </ul>
          </div> 
        </div>

        <div class="row menu-container" data-aos="fade-up" data-aos-delay="200" style="overflow-y: auto;height: 400px;">
            <div *ngFor="let item of this.state.filterMenu" class="col-lg-6 menu-item">
            <!--  <div *ngIf="item.itemImage; then thenBlock else elseBlock"></div> -->
            <ng-template [ngIf]="item.itemImage!=null" [ngIfElse]="elseBlock"> 
              <a [href]="this.imageUrl + item.itemImage" [attr.data-lightbox]="item.itemImage" data-title={{item.itemTitle}}>
                  <img  [src]="this.imageUrl + item.itemImage"  class="menu-img" alt="" />
              </a>
              </ng-template> 
              <ng-template #elseBlock> 
                   <img  [src]="this.imageUrl + this.state.restarantImagePath" class="menu-img" alt="" />
              </ng-template>
           <!--  <ng-template [ngIf]="item.itemImage!=null" [ngIfElse]="elseBlock"> <img  [src]="this.imageUrl + item.itemImage" class="menu-img" alt="" /></ng-template> 
           <ng-template #elseBlock>  <img  [src]="this.imageUrl + this.state.restarantImagePath" class="menu-img" alt="" /></ng-template> -->
              <div class="menu-content">
                <a>{{item.itemTitle}}</a><span>{{item.itemPrice | currency:"&#8377;"}}/-</span>
              </div>
              <div class="menu-ingredients">
                <div class="row">
                  <div class="col-lg-9">    
                  {{item.itemDescription}}
                </div>
                <div class="col-lg-3" *ngIf="item.itemMarkupPrice">
                     <del style="padding-left: 10px;font-size: 16px;">{{item.itemMarkupPrice | currency:"&#8377;"}}/-</del>
                </div>
                </div>
              </div>
              
            </div>
        </div>
 
      </div> 
    </section>
    <section  class="special-items" id="special-items"  #specials>
      <div class="container"  *ngIf="this.specialItems.length>0" data-aos="fade-up">

        <div class="section-title">
          <h2>Specials</h2>
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-lg-3">
            <ul class="nav nav-tabs flex-column">
              <li *ngFor="let item of this.specialItems" class="nav-item">
                <a class="nav-link  show" data-toggle="tab" (click)="setSpecialItemData(item)" >{{item.itemTitle}}</a>
              </li>  
            </ul>
          </div>
          <div class="col-lg-9 mt-4 mt-lg-0">
            <div class="tab-content">
              <div class="tab-pane active show" id="tab-1">
                <div class="row">
                  <div class="col-lg-8 details order-2 order-lg-1">
                    <h3>{{this.state.specialItemTitle}}</h3>
                    <p class="font-italic">{{this.state.specialItemDesc}}</p>
                  </div>
                  <div class="col-lg-4 text-center order-1 order-lg-2">
                    <ng-template [ngIf]="this.state.specialItemImage!==''" [ngIfElse]="spelseBlock"><a [href]="this.imageUrl + this.state.specialItemImage" [attr.data-lightbox]="this.imageUrl + this.state.specialItemImage"> <img class="img-fluid" [src]="this.imageUrl + this.state.specialItemImage" style="border-radius: 50%;width: 225px;height: 225px;" alt="" /></a></ng-template> 
                    <ng-template #spelseBlock>  <img class="img-fluid"  [src]="this.imageUrl + this.state.restarantImagePath" style="border-radius: 50%;width: 225px;height: 225px;" alt="" /></ng-template>
                   <!--  <img [src]="this.imageUrl + this.state.specialItemImage" alt="" class="img-fluid" style="border-radius: 50%;width: 225px;height: 225px;" /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <section id="testimonials" class="testimonials  section-bg" #testimonials >
      <div class="container " data-aos="fade-up" *ngIf="this.state.offers.length>0">

        <div class="section-title">
          <h2>Offers</h2>
          
        </div>

        <div class="row testimonials-carousel" data-aos="zoom-in" data-aos-delay="100">

          <div class="testimonial-item col-lg-3 col-md-4" *ngFor="let o of this.state.offers">
           <ng-template [ngIf]="o.offers!=''" [ngIfElse]="offersElseBlock">            
             <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
            {{o.offers}}
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
           </ng-template>
           <ng-template #offersElseBlock>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
             Grab the offer now!!
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
             
           </ng-template>
           <ng-template [ngIf]="o.offerImg!=''" [ngIfElse]="offerImgElseBlock">
          <a [href]="this.imageUrl + o.offerImg" [attr.data-lightbox]="o.offerImg">  <img [src]="this.imageUrl + o.offerImg" class="testimonial-img" alt=""> </a>
          </ng-template>
          <ng-template  #offerImgElseBlock>
            <img [src]="this.imageUrl + this.state.restarantImagePath" class="testimonial-img" alt="">
          </ng-template>

          </div>

         
        </div>

      </div>
    </section>
    <section id="gallery"   class="gallery" #gallery>

      <div class="container" *ngIf="this.state.images.length>0"  data-aos="fade-up">
        <div class="section-title">
          <h2>Gallery</h2>
        </div>
      </div>

      <div class="container" data-aos="fade-up" data-aos-delay="100">

        <div class="row no-gutters">

          <div *ngFor = "let imgItem of this.state.images" class="col-lg-3 col-md-4">
            <div class="gallery-item">
              <a [href]="this.imageUrl + imgItem" data-lightbox="imgItem">
                <img [src]="this.imageUrl + imgItem"  class="img-fluid" />
              </a>
            </div>
          </div>
        </div>
        </div> 
      
    </section>
   
     <section id="contact" class="contact" #contact > 
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Contact</h2>
        </div>
      </div>

      <div *ngIf="this.state.restarantDetails.location!=''" class="container" data-aos="fade-up">
        
         <iframe width="100%" height="300" [src]="this.gmapUrl | safe"></iframe>
       
      </div>     
      <div class="container" data-aos="fade-up">

        <div class="row mt-5">

          <div class="col-lg-12">
            <div class="info" style="color: white;">
              <div *ngIf="this.state.restarantDetails.address" class="address">
                <i class="icofont-google-map"></i>
                <h4>Location:</h4>
                <p>{{this.state.restarantDetails.address}}</p>
              </div>

              <div *ngIf="this.timings.length>0" class="open-hours">
                <i class="icofont-clock-time icofont-rotate-90"></i>
                <h4>Open Hours:</h4>
                <p *ngFor="let timing of this.timings">
                 <b>{{timing.day | uppercase}}</b> : 
                  {{ timing.slots[0].start_time | convertFrom24To12Format }} To  {{timing.slots[0].end_time | convertFrom24To12Format }}
                </p>
              </div>

              <div *ngIf="this.state.adminDetails.length" class="email">
                <a href="mailto:{{this.state.adminDetails[0].email}}" style="color: cornsilk;"> <i class="icofont-envelope"></i>  </a>
                <h4>Email:</h4>
                <a href="mailto:{{this.state.adminDetails[0].email}}">  <p>{{this.state.adminDetails[0].email}}</p> </a>
              </div>

              <div *ngIf="this.state.restarantDetails.phone" class="phone">
                <a href="tel:{{this.state.restarantDetails.phone}}" style="color: cornsilk;">   <i class="icofont-phone"></i> </a>
                <h4>Call:</h4>
                <a href="tel:{{this.state.restarantDetails.phone}}"> <p> {{this.state.restarantDetails.phone}} </p> </a>
              </div>

            </div>
              
          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">

         
          </div>
         
        </div>
      </div>
    </section>
  </main>   
    <!-- ======= Footer ======= -->
    <footer id="footer">
  
      <div class="container">
        <div *ngIf="this.state.features.length>0" class="d-flex justify-content-center mt-5">
          <label style="font-size: 16px;"> <b>Services</b> </label>
        </div>
        <div class="row">
         
          <div class="col-lg-3 col-md-6 "></div>
          <div *ngIf="this.state.features.length>0" class="col-lg-6 col-md-6 d-flex justify-content-center">
           <h5> <span *ngFor="let f of this.state.features"  style="margin-right: 4px;" class="badge badge-info badge-pill">{{f.feature}}</span></h5>
          </div>
          <div class="col-lg-3 col-md-6"></div>
          
        <!--   <div class="footer-info">
          <div class="col-lg-4 col-md-6 footer-links">
            <label> Our Specialty </label>
              <ul>
                <li><i class="bx bx-chevron-right" *ngIf="this.state.restarantDetails.pureVeg!=null"></i> <a href="#">Pure Veg</a></li>
                <li><i class="bx bx-chevron-right" *ngIf="this.state.restarantDetails.speciality!=null"></i> <a href="#">{{this.state.restarantDetails.speciality}}</a></li>
                <li><i class="bx bx-chevron-right" *ngIf="this.state.deliveryTime.length>0" ></i> <a href="#">Delivery Time : {{this.state.deliveryTime[0]}} to {{this.state.deliveryTime[1]}} mins</a></li>
                <li><i class="bx bx-chevron-right" *ngIf="this.state.pickupTime.length>0"></i> <a href="#">Pickup Time : {{this.state.pickupTime[0]}} to {{this.state.pickupTime[1]}} mins</a></li>
              </ul>
            </div>
  
            <div *ngIf="this.state.features.length>0" class="col-lg-4 col-md-6 footer-links">
              <label> Our Features </label>
              <ul>
                <li *ngFor="let f of this.state.features"><i class="bx bx-chevron-right"></i> <a href="#">{{f.feature}}</a></li>
               
              </ul>
            </div>
          </div> -->
       
           <div class="social-links mt-3">
            <a *ngIf="this.state.restarantDetails.link" [href]="this.state.restarantDetails.link" target="_blank" class="youtube"><i
              class="bx bxl-youtube"></i></a>
             <a  *ngIf="this.state.restarantDetails.twiterlink"  [href]="this.state.restarantDetails.twiterlink" target="_blank" class="twitter"><i class="bx bxl-twitter"></i></a>
             <a *ngIf="this.state.restarantDetails.fblink" [href]="this.state.restarantDetails.fblink" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
             <a *ngIf="this.state.restarantDetails.instalink" [href]="this.state.restarantDetails.instalink" target="_blank" class="instagram"><i class="bx bxl-instagram"></i></a>
             <!-- <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
             <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a> -->
           </div>
         </div>
        <div class="copyright">
          &copy; Copyright <strong><span>Buvvas</span></strong>. All Rights Reserved
        </div>
        <div class="credits">
          Designed by <a href="https://buvvas.com/">Buvvas</a>
        </div>
      </div>
    </footer><!-- End Footer -->  
    <!-- <div id="preloader"></div> -->
    <a (click)="scroll(hero,'hero',1)"  class="back-to-top"><i class="bx bx-up-arrow-alt"></i></a>
  
</div>
<app-user-login (userObj)="getUserDetails($event)" *ngIf="this.loginFlag"></app-user-login>
</body>

