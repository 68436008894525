import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomerPortalComponent } from './customer-portal/customer-portal.component';
import { HttpClientModule } from '@angular/common/http';
import { PageScrollDirective } from './page-scroll.directive';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {SafePipe} from './safe.pipe'
import {TimeFormat} from './convertTimeFormat.pipe';
import { CustomerPortalNewtemplateComponent } from './customer-portal-newtemplate/customer-portal-newtemplate.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { TemplateSwitchComponent } from './template-switch/template-switch.component';
import { CarouselModule } from 'ngx-owl-carousel-o';



@NgModule({
  declarations: [
    AppComponent,
    CustomerPortalComponent,
    PageScrollDirective,
    SafePipe, 
    TimeFormat, CustomerPortalNewtemplateComponent, UserLoginComponent, TemplateSwitchComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    BrowserAnimationsModule,
    CarouselModule
  ],
  providers: [ ],
  bootstrap: [AppComponent]
})
export class AppModule { }
